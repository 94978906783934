import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// PDF Document Component
const CestasPDF = () => (
  <Document title="Cestas de Piquenique">
    <Page size="A4" style={{
      padding: 50,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Header with Logo */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        paddingBottom: 10,
      }}>
        <PDFImage src="/Logo Jd Amazonia.png" style={{
          width: 140,
          height: 70,
          objectFit: 'contain',
        }} />
      </View>
      
      {/* Title */}
      <Text style={{
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#4b6455',
      }}>Cestas de Piquenique</Text>
      
      <Text style={{
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 30,
        fontStyle: 'italic',
        color: '#666',
      }}>
        Momentos Especiais ao Ar Livre
      </Text>
      
      {/* Content */}
      <View style={{
        fontSize: 12,
        lineHeight: 1.6,
        marginBottom: 40,
      }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Cesta Básica - "Delícias Simples"</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Ruffles (1 pacote)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Amendoim Dori Japonês (200g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Vinho Tinto ou Vinho Branco (1 garrafa)</Text>
          <Text style={{ fontSize: 10, marginBottom: 15, fontWeight: 'bold' }}>Valor: R$ 90,00</Text>
        </View>
        
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Cesta Premium - "Sabores Especiais"</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Queijo Colonial (200g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Salaminho (100g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Azeitonas Verdes (100g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Pão Artesanal (1 unidade)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Geleia de Cupuaçu (50g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Castanha-do-Pará (100g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Vinho Tinto ou Vinho Branco (1 garrafa)</Text>
          <Text style={{ fontSize: 10, marginBottom: 15, fontWeight: 'bold' }}>Valor: R$ 180,00</Text>
        </View>
        
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Cesta Luxo - "Experiência Amazônica"</Text>
        
        <View>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Queijo Colonial (200g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Presunto de Parma (100g)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Mix de Frutas da Estação</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Chocolate Amazônico Artesanal (2 barras)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Pão Artesanal (1 unidade)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Geleia de Cupuaçu e Castanha (2 sabores)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Água Mineral (2 garrafas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Champagne ou Espumante (1 garrafa)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Copo e Taça de Acrílico (2 de cada)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5, fontWeight: 'bold' }}>Valor: R$ 320,00</Text>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 50,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
        color: '#666',
        fontStyle: 'italic',
      }}>As cestas são montadas com produtos frescos e necessitam de 2 horas para preparação.</Text>
      
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1pt solid #4b6455',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
    </Page>
  </Document>
);

function Cestas({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-3xl mx-auto">
      <PDFPreview 
        document={<CestasPDF />} 
        fileName="cestas-piquenique.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default Cestas; 