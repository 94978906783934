// Authentication utilities for secure document access

// Using environment variables for credentials instead of hardcoding them
// This is safer for version control and deployment
const ADMIN_CREDENTIALS = {
  username: process.env.REACT_APP_ADMIN_USERNAME,
  password: process.env.REACT_APP_ADMIN_PASSWORD
};

// Local storage key for session
const AUTH_TOKEN_KEY = 'jardimamazonia_auth_token';
const AUTH_USER_KEY = 'jardimamazonia_auth_user';

/**
 * Attempts to log in a user with the provided credentials
 * @param {string} username - The username to authenticate
 * @param {string} password - The password to authenticate
 * @returns {Promise<{success: boolean, message: string, user?: object}>} - Login result
 */
export const login = async (username, password) => {
  // In a real app, this would be an API call to a secure backend
  return new Promise((resolve) => {
    // Simple timeout to simulate network request
    setTimeout(() => {
      if (username === ADMIN_CREDENTIALS.username && password === ADMIN_CREDENTIALS.password) {
        // Create a session token (in a real app, this would be a JWT)
        const token = `token_${Date.now()}_${Math.random().toString(36).substr(2, 9)}`;
        const user = { username, role: 'admin' };
        
        // Store in localStorage (in a real app, this might use secure cookies instead)
        localStorage.setItem(AUTH_TOKEN_KEY, token);
        localStorage.setItem(AUTH_USER_KEY, JSON.stringify(user));
        
        resolve({ 
          success: true, 
          message: 'Login successful',
          user
        });
      } else {
        resolve({ 
          success: false, 
          message: 'Invalid username or password'
        });
      }
    }, 500);
  });
};

/**
 * Logs out the current user by clearing authentication data
 */
export const logout = () => {
  localStorage.removeItem(AUTH_TOKEN_KEY);
  localStorage.removeItem(AUTH_USER_KEY);
};

/**
 * Checks if a user is currently authenticated
 * @returns {boolean} True if authenticated, false otherwise
 */
export const isAuthenticated = () => {
  return !!localStorage.getItem(AUTH_TOKEN_KEY);
};

/**
 * Gets the current authenticated user
 * @returns {object|null} User object or null if not authenticated
 */
export const getCurrentUser = () => {
  const userJson = localStorage.getItem(AUTH_USER_KEY);
  if (!userJson) return null;
  
  try {
    return JSON.parse(userJson);
  } catch (e) {
    // If there's a parsing error, clear the auth data and return null
    logout();
    return null;
  }
}; 