import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// Import the images
import macacopreto from './fotos_roteiro/macaco-preto.png';
import trilhas from './fotos_roteiro/trilhas.jpg';
import chegada from './fotos_roteiro/chegada.jpg';
import barco from './fotos_roteiro/barco.jpg';
import macaco from './fotos_roteiro/macaco.jpeg';
import angelim from './fotos_roteiro/angelim.jpg';
import lagoa from './fotos_roteiro/lagoa-arara.jpg';
import piquia from './fotos_roteiro/piquia.jpg';
import parauacu from './fotos_roteiro/parauacu.jpeg';
import guias from './fotos_roteiro/guias-trilha.jpg';

// PDF Document Component
const RoteiroPDF = () => (
  <Document title="Roteiro Jardim Amazônia">
    {/* First Page - Cover & Days 1-2 */}
    <Page size="A4" style={{
      padding: 30,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Logo Header */}
      <View style={{
        marginBottom: 20,
        alignItems: 'center',
      }}>
        <PDFImage 
            src="/Logo Jd Amazonia.png" 
          style={{
            width: 80,
            height: 80,
            objectFit: 'contain',
          }}
        />
      </View>
      
      {/* Hero Image */}
      <View style={{
        width: '100%',
        height: 180,
        marginBottom: 15,
        position: 'relative',
      }}>
        <PDFImage
            src={trilhas} 
          style={{
            width: '100%',
            height: '100%',
            objectFit: 'cover',
          }}
        />
        <View style={{
          position: 'absolute',
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
          padding: 15,
          justifyContent: 'flex-end',
        }}>
          <Text style={{
            color: 'white',
            fontSize: 24,
            fontWeight: 'bold',
            marginBottom: 5,
          }}>
            Roteiro Jardim Amazônia
          </Text>
          <Text style={{
            color: 'white',
            fontSize: 12,
          }}>
            Uma jornada pela natureza amazônica
          </Text>
        </View>
      </View>
      
      {/* Divider */}
      <View style={{
        width: 60,
        height: 1,
        backgroundColor: '#4b6455',
        alignSelf: 'center',
        marginBottom: 15,
      }} />
      
      {/* Introduction */}
      <View style={{
        marginBottom: 15,
        alignItems: 'center',
      }}>
        <Text style={{
          fontSize: 11,
          fontStyle: 'italic',
          textAlign: 'center',
          marginHorizontal: 40,
          color: '#555',
          lineHeight: 1.6,
        }}>
              Bem-vindo à experiência exclusiva do Jardim Amazônia Lodge. Este roteiro foi cuidadosamente planejado para 
              proporcionar uma imersão completa na natureza amazônica, com foco na observação de fauna e flora, 
              especialmente primatas raros e aves exuberantes da região.
        </Text>
      </View>
      
      {/* Day 1 */}
      <View style={{
        marginBottom: 15,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>1</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 1 – Chegada na Pousada
          </Text>
        </View>
        
        <View style={{
          flexDirection: 'row',
          marginBottom: 0,
          minHeight: 80,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              color: '#4b6455',
              marginBottom: 5,
            }}>
              16h00 - Chegada no Lodge e check-in
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
                      Check-in e breve tour de familiarização da área e das atividades dos próximos dias, 
                      jantar e pernoite.
            </Text>
          </View>
          <View style={{ flex: 1 }}>
            <PDFImage
              src={chegada}
              style={{
                width: '100%',
                height: 160,
                borderRadius: 4,
                objectFit: 'cover',
                marginTop: -40, // Added negative margin to move image up
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'right',
              marginTop: 2,
            }}>
              Vista do por do sol na Jardim Amazônia
            </Text>
          </View>
        </View>
      </View>
      
      {/* Day 2 - Part 1 */}
      <View style={{
        marginBottom: 15,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>2</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 2 – Safari no Rio e Trilhas
          </Text>
        </View>
        
        <View style={{
          flexDirection: 'row',
          marginBottom: 15,
          minHeight: 80,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              8h00 - Safari no Rio Claro - Lagoa do Tie Bicudo (2h de barco)
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
                  Após café da manhã, safari de barco a motor até a "Lagoa do Tiê Bicudo" para exploração e observação de aves e peixes, retorno para pousada e almoço.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
                  src={barco} 
              style={{
                width: '100%',
                height: 100,
                borderRadius: 4,
                objectFit: 'cover',
                marginTop: -40,
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Safari de barco pelo Rio Claro
            </Text>
          </View>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>
        Jardim da Amazônia Lodge - DESDE 1997   •   Página 1/3
      </Text>
    </Page>
    
    {/* Second Page - Days 2 (cont) & 3 */}
    <Page size="A4" style={{
      padding: 30,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Page Header */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
        paddingBottom: 5,
        borderBottom: '1pt solid #4b6455',
      }}>
        <PDFImage 
          src="/Logo Jd Amazonia.png"
          style={{
            width: 80,
            height: 40,
            objectFit: 'contain',
          }}
        />
        <Text style={{ fontSize: 10, marginTop: 20 }}>
          Roteiro Jardim Amazônia
        </Text>
      </View>
      
      {/* Day 2 Continued */}
      <View style={{
        marginBottom: 15,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>2</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 2 – Continuação
          </Text>
        </View>
        
        <View style={{
          flexDirection: 'row',
          marginBottom: 15,
          minHeight: 90,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              14h00 - Trilha do Jatobá
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
                  Após o almoço, iniciamos na trilha próxima a nascente de águas cristalinas, para observação de primatas e aves, 
              passando pela Passarela do Buriti - o habitat do Parauacu (Pithecia mittermeieri).
      
              Trilha leve que percorre 300m de caminhada
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
              src={parauacu}
              style={{
                width: '100%',
                height: 120,
                borderRadius: 4,
                objectFit: 'cover',
                marginTop: -40,
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Parauacu ou Amazonian Saki Monkey (Pithecia mittermeieri)
            </Text>
          </View>
        </View>
        
        <View style={{ 
          flexDirection: 'row',
          backgroundColor: '#f0f4f1', 
          padding: 1, 
          borderRadius: 4,
          minHeight: 90,
          marginLeft: 0,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              16h30 - Pôr do Sol no Mirante / Observatório Aves & Primatas
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
              marginLeft: 0,
            }}>
              Neste local, a vista se abre para as copas das árvores, revelando a vida da floresta amazônica. 
              É possível observar aves em pleno voo e, com sorte, avistar 
              espécies de primatas em seu habitat natural.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
              src={lagoa}
              style={{
                width: '100%',
                height: 90,
                borderRadius: 4,
                objectFit: 'cover',
                objectPosition: '20%',
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Pôr do sol na Amazônia
            </Text>
          </View>
        </View>
      </View>
      
      {/* Day 3 */}
      <View style={{
        marginBottom: 15,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>3</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 3 – Em Busca dos Primatas
          </Text>
        </View>
        
        <View style={{
          flexDirection: 'row',
          marginBottom: 15,
          minHeight: 100,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              color: '#4b6455',
              marginBottom: 5,
            }}>
              6h00 - Em busca do Macaco-aranha-da-cara-preta
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
              Após café da manhã, saída para a trilha dos primatas, onde há sítios de
              alimentação de saguis-do-rabo-preto e árvores-dormitório de um grupo de
              macaco-aranha-da-cara-preta o maior primata frugívoro da região que está em perigo 
              de extinção, chegada no mirante, elevado de interação com a vida silvestre.
              Retorno e almoço às 12h30.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
                  src={macacopreto} 
              style={{
                width: '100%',
                height: 140,
                borderRadius: 4,
                objectFit: 'cover',
                marginTop: -40,
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Macaco-aranha-da-cara-preta
            </Text>
          </View>
        </View>
        
        <View style={{
          marginTop: 30,
          flexDirection: 'row', 
          marginBottom: 5,
          minHeight: 90,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              15h00 - Trilha do Angelim
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
              Navegação de barco à motor até a majestosa "Trilha do Angelim", onde teremos a 
              oportunidade de avistar o macaco-aranha em seu habitat natural. O percurso combina 
              um relaxante passeio de barco com uma trilha de dificuldade média (1.600m ida e volta). 
              Durante o trajeto, faremos observação de aves, com destaque para o raro Anambé-preto 
              (Cephalopterus ornatus), e visitaremos a encantadora Praia do Fogo. Retornamos ao 
              entardecer para jantar e pernoite.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
              src={angelim}
              style={{
                width: '100%',
                height: 120,
                borderRadius: 4,
                objectFit: 'cover',
                objectPosition: '20%',
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Angelim: principal árvore onde macacos-aranha colhem seu alimento
            </Text>
          </View>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>
        Jardim da Amazônia Lodge - DESDE 1997 • Página 2/3
      </Text>
    </Page>
    
    {/* Third Page - Days 4 & 5 */}
    <Page size="A4" style={{
      padding: 30,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Page Header */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'space-between',
        marginBottom: 15,
        paddingBottom: 5,
        borderBottom: '1pt solid #4b6455',
      }}>
        <PDFImage 
          src="/Logo Jd Amazonia.png" 
          style={{
            width: 80,
            height: 40,
            objectFit: 'contain',
          }}
        />
        <Text style={{ fontSize: 10, marginTop: 20 }}>
          Roteiro Jardim Amazônia
        </Text>
      </View>
      
      {/* Day 4 */}
      <View style={{
        marginBottom: 20,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>4</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 4 – Zogue-Zogue e Pôr do Sol
          </Text>
        </View>
        
        <View style={{ 
          flexDirection: 'row',
          marginBottom: 15,
          minHeight: 110,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              5h30 - Café da manhã com Zogue-Zogue-de-Mato-Grosso
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
              Após percorrer cerca de 16 km de estrada, chegamos à margem direita do Rio
              Arinos. Durante o café da manhã às margens do rio, os visitantes terão a oportunidade única de ouvir o canto em dueto do Zogue-Zogue-de-Mato-Grosso
              (Plecturocebus govesi), um primata criticamente ameaçado de extinção e encontrado apenas no norte de Mato Grosso. Uma experiência verdadeiramente especial para os amantes da natureza.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
              src={guias}
              style={{
                width: '100%',
                height: 110,
                borderRadius: 4,
                objectFit: 'cover',
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Observação de fauna
            </Text>
          </View>
        </View>
        
        <View style={{ 
          flexDirection: 'row',
          marginBottom: 5,
          minHeight: 80,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
              marginTop: 10,
            }}>
              15h00 - Mirante & 16h30 - Pôr do Sol no "Ninhal das Araras"
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
              Saída para o mirante, onde poderemos observar a fauna ao redor do lodge.
              Às 16h30, contemplaremos um espetacular pôr do sol sobre o "Ninhal das Araras", seguido de jantar e pernoite na pousada.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
                  src={macaco} 
              style={{
                width: '100%',
                height: 60,
                borderRadius: 4,
                objectFit: 'cover',
                objectPosition: '10%',
                marginTop: 10,
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Observação de primatas e aves no Mirante
            </Text>
          </View>
        </View>
      </View>
      
      {/* Day 5 */}
      <View style={{
        marginTop: 10,
        marginBottom: 15,
        borderRadius: 4,
        padding: 15,
        backgroundColor: '#f9f9f9',
      }}>
        <View style={{
          flexDirection: 'row',
          alignItems: 'center',
          marginBottom: 10,
        }}>
          <View style={{
            width: 25,
            height: 25,
            borderRadius: 15,
            backgroundColor: '#4b6455',
            justifyContent: 'center',
            alignItems: 'center',
            marginRight: 10,
          }}>
            <Text style={{ color: 'white', fontSize: 12, fontWeight: 'bold' }}>5</Text>
          </View>
          <Text style={{
            fontSize: 16,
            fontWeight: 'bold',
            color: '#4b6455',
          }}>
            Dia 5 – Trilha do Piquia
          </Text>
        </View>
        <Text style={{
              fontSize: 10,
              fontWeight: 'bold',
              marginBottom: 5,
              color: '#4b6455',
            }}>
              6h00 - Café da manhã com Piquia
            </Text>
        <View style={{
          flexDirection: 'row',
          marginBottom: 10,
          minHeight: 110,
        }}>
          <View style={{ flex: 3, marginRight: 10 }}>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
              marginBottom: 8,
            }}>
              Após o café da manhã, partimos de barco a motor com destino à "Trilha do Piquiá",
              atravessando o Rio Claro por aproximadamente 10 minutos. O
              percurso de 1.500 metros nos leva por uma exuberante floresta densa, onde podemos admirar um grupo
              de majestosas árvores Piquiá e nos encantar com o som marcante do pássaro Cricrió (Lipaugus vociferans), 
              uma das vocalizações mais características e memoráveis da Amazônia.
            </Text>
            <Text style={{
              fontSize: 10,
              lineHeight: 1.5,
            }}>
              Durante o trajeto, teremos a oportunidade de observar a rica biodiversidade local e aprender sobre 
              a importância ecológica desta espécie de árvore para o ecossistema. Retornamos ao lodge para almoço às 12h30.
            </Text>
          </View>
          <View style={{ flex: 2 }}>
            <PDFImage
                  src={piquia} 
              style={{
                width: '100%',
                height: 180,
                borderRadius: 4,
                objectFit: 'cover',
                objectPosition: '0%',
                marginTop: -10,
              }}
            />
            <Text style={{
              fontSize: 7,
              color: '#777',
              textAlign: 'center',
              marginTop: 2,
            }}>
              Trilha do Piquia
            </Text>
          </View>
        </View>
      </View>
      
      {/* Extra Info */}
      <View style={{
        marginTop: 5,
        padding: 15,
        backgroundColor: '#f0f4f1',
        borderRadius: 4,
      }}>
        <Text style={{
          fontSize: 10,
          fontWeight: 'bold',
          marginBottom: 5,
          color: '#4b6455',
          textAlign: 'center',
        }}>
          Informações Importantes
        </Text>
        <Text style={{
          fontSize: 9,
          lineHeight: 1.5,
          textAlign: 'center',
        }}>
          • O que trazer: repelente, protetor solar, chapéu/boné, binóculos, máquina fotográfica, roupas leves e calçados confortáveis{'\n'}
          • Observação: O roteiro está sujeito a alterações conforme condições climáticas e presença da fauna local
        </Text>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>
        Jardim da Amazônia Lodge - DESDE 1997 • Página 3/3
      </Text>
    </Page>
  </Document>
);

function RoteiroJardim({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-[21cm] mx-auto">
      <PDFPreview 
        document={<RoteiroPDF />} 
        fileName="roteiro-jardim-amazonia.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default RoteiroJardim; 