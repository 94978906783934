import React, { useState, useEffect, useRef } from 'react';
import { pdf } from '@react-pdf/renderer';
import { FaFilePdf } from 'react-icons/fa';

// Add Buffer polyfill for browser environment
// This is needed because @react-pdf/renderer uses Node.js Buffer
if (typeof window !== 'undefined' && typeof window.Buffer === 'undefined') {
  window.Buffer = require('buffer/').Buffer;
}

/**
 * Simplified PDFPreview component to avoid infinite loading issues
 */
const PDFPreview = ({ 
  document: PDFDocument, 
  fileName, 
  showDownloadButton = true,
  height = '29.7cm'
}) => {
  const [pdfBlob, setPdfBlob] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [pdfUrl, setPdfUrl] = useState(null);
  const urlRef = useRef(null);
  
  // Generate PDF blob when component mounts or PDFDocument changes
  useEffect(() => {
    let mounted = true;
    
    async function generatePdf() {
      try {
        setLoading(true);
        setError(null);
        
        // Cleanup old URL if it exists
        if (urlRef.current) {
          URL.revokeObjectURL(urlRef.current);
          urlRef.current = null;
        }
        
        // Generate PDF blob
        const blob = await pdf(PDFDocument).toBlob();
        
        if (mounted) {
          setPdfBlob(blob);
          
          // Create URL from blob
          const url = URL.createObjectURL(blob);
          urlRef.current = url;
          setPdfUrl(url);
          
          setLoading(false);
        }
      } catch (err) {
        console.error('Error generating PDF:', err);
        
        let errorMessage = 'Error generating PDF';
        
        // Add more specific error messages for common issues
        if (err.message && err.message.includes('fetch')) {
          errorMessage = 'Error loading images for PDF. Check your image paths.';
        } else if (err.message) {
          errorMessage = `Error: ${err.message}`;
        }
        
        if (mounted) {
          setError(errorMessage);
          setLoading(false);
        }
      }
    }
    
    if (PDFDocument) {
      generatePdf();
    }
    
    // Cleanup function
    return () => {
      mounted = false;
      // Revoke any URLs when component unmounts
      if (urlRef.current) {
        URL.revokeObjectURL(urlRef.current);
        urlRef.current = null;
      }
    };
  }, [PDFDocument]); // Only depend on PDFDocument changes
  
  const handleDownload = () => {
    if (!pdfBlob) return;
    
    // Create a temporary link element
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.setAttribute('download', fileName || 'document.pdf');
    document.body.appendChild(link);
    
    // Trigger download
    link.click();
    
    // Cleanup
    document.body.removeChild(link);
  };
  
  if (error) {
    return (
      <div className="w-full p-4 bg-red-50 text-red-600 border border-red-200 rounded mb-4">
        <p className="font-medium">Erro ao gerar o PDF</p>
        <p className="text-sm">{error}</p>
        <button 
          onClick={() => window.location.reload()}
          className="mt-2 text-sm bg-red-100 hover:bg-red-200 text-red-800 px-3 py-1 rounded"
        >
          Recarregar página
        </button>
      </div>
    );
  }
  
  return (
    <div className="w-full flex flex-col items-center">
      {loading ? (
        <div className="w-full flex justify-center items-center py-12">
          <div className="animate-spin rounded-full h-12 w-12 border-t-2 border-b-2 border-green-700"></div>
        </div>
      ) : (
        <>
          <div className="w-full bg-gray-100 rounded-lg overflow-hidden mb-4" style={{ height }}>
            {pdfUrl && (
              <iframe 
                src={pdfUrl} 
                className="w-full h-full"
                title="PDF Preview"
              />
            )}
          </div>
          
          {showDownloadButton && pdfUrl && (
            <button
              onClick={handleDownload}
              className="bg-green-700 hover:bg-green-800 text-white font-medium py-2 px-6 rounded-lg flex items-center justify-center transition-all duration-200"
            >
              <FaFilePdf className="mr-2" />
              Baixar PDF
            </button>
          )}
        </>
      )}
    </div>
  );
};

export default PDFPreview; 