import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// PDF Document Component
const WineListPDF = () => (
  <Document title="Carta de Vinhos Jardim Amazônia">
    <Page size="A4" style={{
      padding: 50,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Header with Logo */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        paddingBottom: 10,
      }}>
        <PDFImage src="/Logo Jd Amazonia.png" style={{
          width: 140,
          height: 70,
          objectFit: 'contain',
        }} />
      </View>
      
      {/* Title */}
      <Text style={{
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#4b6455',
      }}>Carta de Vinhos</Text>
      
      {/* Wine List Content */}
      <View style={{
        fontSize: 12,
        lineHeight: 1.6,
        marginBottom: 40,
      }}>
        {/* Red Wines */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Vinhos Tintos</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Cabernet Sauvignon - Chile</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Vinho encorpado com notas de frutas negras e taninos maduros</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Malbec - Argentina</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Aroma intenso de amoras e ameixas, com um toque de baunilha</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Merlot - Brasil</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Leve e frutado, com sabor equilibrado e acidez moderada</Text>
        </View>
        
        {/* White Wines */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Vinhos Brancos</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Chardonnay - Chile</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Refrescante, com notas cítricas e mineralidade elegante</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Sauvignon Blanc - Nova Zelândia</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Aroma exuberante de frutas tropicais e ervas frescas</Text>
        </View>
        
        {/* Sparkling Wines */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Espumantes</Text>
        
        <View>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Prosecco - Itália</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Espumante leve e refrescante com notas de maçã verde e pêra</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Espumante Brut - Brasil</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Bolhas finas e persistentes, com aroma de frutas cítricas e pão tostado</Text>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1pt solid #4b6455',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
    </Page>
  </Document>
);

function WineList({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-3xl mx-auto">
      <PDFPreview 
        document={<WineListPDF />} 
        fileName="carta-vinhos-jardim-amazonia.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default WineList; 