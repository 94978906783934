// Auto-generated from Cloudinary CSV export
// Do not edit this file directly - Generated on 2025-02-25T02:28:26.660Z

import { Cloudinary } from '@cloudinary/url-gen';

export const cld = new Cloudinary({
  cloud: { cloudName: 'dxlhv2mji' }
});

export const CLOUDINARY_BASE_URL = 'https://res.cloudinary.com/dxlhv2mji/image/upload';
export const DEFAULT_TRANSFORMATIONS = 'f_auto,q_auto';

// Add this helper function for optimized image URLs
export function getOptimizedImageUrl(imageUrl, width = 1200, quality = 'auto:good') {
  if (!imageUrl) return '';
  
  // Check if it's a Cloudinary URL
  if (imageUrl.includes('cloudinary.com')) {
    return imageUrl.replace(
      '/upload/',
      `/upload/c_scale,w_${width},f_auto,q_${quality}/`
    );
  }
  
  return imageUrl;
}

export const folderImageMapping = {
  "home": {
    "about-image": "default_about_image",
    "hero": "Jardim_da_Amazônia-1_nmhh66"
  },
  "about": {},
  "mapa": {
    "jd-amazonia-map-d1k1zb": "Jd_Amazonia_map_d1k1zb"
  },
  "birdwatching": {
    "video-tie-bicudo-jpqggz": {
      "id": "video_tie_bicudo_jpqggz",
      "photographer": "Manoel Ruedi"
    },
    "parauacu---2013---jhonata-araujo-1-wzw3ku": {
      "id": "parauacu_-_2013_-_jhonata_araujo_1_wzw3ku",
      "photographer": "Jhonata Araujo"
    },
    "ninho-beija-flor-by-manoel-ruedi-117-t5izpv": {
      "id": "ninho_beija_flor_by_MANOEL_RUEDI_117_t5izpv",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-2-s8ufgy": {
      "id": "MANOEL_RUEDI_2_s8ufgy",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-117-whajew": {
      "id": "MANOEL_RUEDI_117_whajew",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-90-dedxfy": {
      "id": "MANOEL_RUEDI_90_dedxfy",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-82-xor41e": {
      "id": "MANOEL_RUEDI_82_xor41e",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-122-liksbm": {
      "id": "MANOEL_RUEDI_122_liksbm",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-111-dwyxeo": {
      "id": "MANOEL_RUEDI_111_dwyxeo",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-78-stjyug": {
      "id": "MANOEL_RUEDI_78_stjyug",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-71-e2kbfn": {
      "id": "MANOEL_RUEDI_71_e2kbfn",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-121-pvdynq": {
      "id": "MANOEL_RUEDI_121_pvdynq",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-120-i9dmov": {
      "id": "MANOEL_RUEDI_120_i9dmov",
      "photographer": "Manoel Ruedi"
    },
    "fotos-jj-5-kahopb": "Fotos_JJ_5_kahopb",
    "fotos-jj-4-vmxd5e": "Fotos_JJ_4_vmxd5e",
    "fotos-jj-3-nkftek": "Fotos_JJ_3_nkftek",
    "choca-dagua---jhonata-araujo-7-q86nhg": {
      "id": "choca_dagua_-_JHONATA_ARAUJO_7_q86nhg",
      "photographer": "Jhonata Araujo"
    },
    "maracana-do-buriti--jhonata-araujo-10-ur2fgl": {
      "id": "MARACANA_DO_BURITI-_JHONATA_ARAUJO_10_ur2fgl",
      "photographer": "Jhonata Araujo"
    },
    "manoel-ruedi-87-ft5hwu": {
      "id": "MANOEL_RUEDI_87_ft5hwu",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-84-hh2jwj": {
      "id": "MANOEL_RUEDI_84_hh2jwj",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-70-ierwkw": {
      "id": "MANOEL_RUEDI_70_ierwkw",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-52-wuywyv": {
      "id": "MANOEL_RUEDI_52_wuywyv",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-15-efedvg": {
      "id": "MANOEL_RUEDI_15_efedvg",
      "photographer": "Manoel Ruedi"
    },
    "jhonata-jr-9-djd00c": {
      "id": "JHONATA_JR_9_djd00c",
      "photographer": "Jhonata Araujo"
    },
    "jhonata-jr-8-tdl8ww": {
      "id": "JHONATA_JR_8_tdl8ww",
      "photographer": "Jhonata Araujo"
    },
    "jhonata-jr-7-ech9ya": {
      "id": "JHONATA_JR_7_ech9ya",
      "photographer": "Jhonata Araujo"
    },
    "jhonata-araujo---udu-de-coroa-azul-4-smatkv": {
      "id": "JHONATA_ARAUJO_-_UDU_DE_COROA_AZUL_4_smatkv",
      "photographer": "Jhonata Araujo"
    },
    "jhonata-araujo-5-tvmqby": {
      "id": "JHONATA_ARAUJO_5_tvmqby",
      "photographer": "Jhonata Araujo"
    },
    "jhonata-araujo---pica-pau-anao-dourado-3-ecrgqf": {
      "id": "JHONATA_ARAUJO_-_PICA-PAU_ANAO_DOURADO_3_ecrgqf",
      "photographer": "Jhonata Araujo"
    },
    "fotos-vincent-kurt-6-jvzysx": {
      "id": "fotos_VINCENT_KURT_6_jvzysx",
      "photographer": "Vincent Kurt Lo"
    },
    "cópia-de-haroldo-palo-jr-5-hazok9": "Cópia_de_HAROLDO_PALO_JR_5_hazok9",
    "bico-de-prata---pipira-vermelha-haroldo-palo-jr-2-aeio1z": "bico_de_prata_-_pipira_vermelha_HAROLDO_PALO_JR_2_aeio1z",
    "beija-flor-tesoura-verde-jhonata-araujo-2-xk7oou": {
      "id": "beija_flor_tesoura_verde_JHONATA_ARAUJO_2_xk7oou",
      "photographer": "Jhonata Araujo"
    },
    "tie-bicudo-jh-hfnk4c": {
      "id": "tie_bicudo_jh_hfnk4c",
      "photographer": "Jhonata Araujo"
    },
    "hypoedaleus guttatus-ilemhy": "hypoedaleus guttatus_ilemhy",
    "vincent-kurt-lo-2-1-lszb1c": {
      "id": "Vincent_Kurt_lo_2_1_lszb1c",
      "photographer": "Vincent Kurt Lo"
    },
    "saira-de-cabeça-azul--vincent-kurt-lo-1-z5hik4": {
      "id": "saira_de_cabeça_azul_-Vincent_Kurt_lo_1_z5hik4",
      "photographer": "Vincent Kurt Lo"
    },
    "sai-andorinha-vincent-kurt-5-nz36qm": {
      "id": "Sai_andorinha_VINCENT_KURT_5_nz36qm",
      "photographer": "Vincent Kurt Lo"
    },
    "martin-pescador-miudo---jhonata-araujo---6-gwslgv": {
      "id": "martin_pescador_miudo_-_JHONATA_ARAUJO_-_6_gwslgv",
      "photographer": "Jhonata Araujo"
    },
    "manoel-ruedi-124-whua95": {
      "id": "MANOEL_RUEDI_124_whua95",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-118-wjwtlw": {
      "id": "MANOEL_RUEDI_118_wjwtlw",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-98-zxegpb": {
      "id": "MANOEL_RUEDI_98_zxegpb",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-97-k0ryqm": {
      "id": "MANOEL_RUEDI_97_k0ryqm",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-94-ba2pto": {
      "id": "MANOEL_RUEDI_94_ba2pto",
      "photographer": "Manoel Ruedi"
    },
    "umbrella-bird-goycom": "umbrella_bird_goycom",
    "birdwatching-yywf16": "birdwatching_yywf16",
    "birdwatching-roqgxe": "birdwatching_roqgxe",
    "birds-wtstwh": "birds_wtstwh",
    "curica-enlrcd": "curica_enlrcd",
    "tie-bicudo-wblb9s": "tie-bicudo_wblb9s",
    "mutum-iwmptx": "mutum_iwmptx"
  },
  "primatas": {
    "video-parauacu2-khrzuo": "video_parauacu2_khrzuo",
    "video-parauacu-qdio4o": "video_parauacu_qdio4o",
    "parauacu-tsk80b": "parauacu_tsk80b",
    "macaco-cara-preta-ilsz9z": "macaco-cara-preta_ilsz9z",
    "zogue-zogue-1-o0mrje": "zogue-zogue_1_o0mrje",
    "primates-vklwye": "primates_vklwye",
    "primates-hero-lodpmk": "primates-hero_lodpmk",
    "macaco-aranha-b3z78a": "macaco-aranha_b3z78a",
    "zogue-zogue-eqbdel": "zogue-zogue_eqbdel",
    "parauacu-udmaqc": "parauacu_udmaqc"
  },
  "logo": {
    "icon-jardim-amazonia-icon-ewbegs": "ICON_Jardim_Amazonia_ICON_ewbegs",
    "old-logo-px6hs1": "old_logo_px6hs1",
    "icon-jardim-amazonia-mono-aig95p": "ICON_Jardim_Amazonia_MONO_aig95p",
    "logo-jardim-amazonia-mono-tfjrct": "Logo_Jardim_Amazonia_MONO_tfjrct",
    "logo-jardim-amazonia-full-si8vyk": "Logo_Jardim_Amazonia_FULL_si8vyk"
  },
  "bangalo-jardim": {
    "bangalo-externo-pjymah": "bangalo_externo_pjymah",
    "jardim-da-amazônia-587-m1zqc2": {
      "id": "Jardim_da_Amazônia-587_m1zqc2",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-560-fd4jem": {
      "id": "Jardim_da_Amazônia-560_fd4jem",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-639-qrstks": {
      "id": "Jardim_da_Amazônia-639_qrstks",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-549-yzjzzh": {
      "id": "Jardim_da_Amazônia-549_yzjzzh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-500-j71e2m": {
      "id": "Jardim_da_Amazônia-500_j71e2m",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-476-hmn9lp": {
      "id": "Jardim_da_Amazônia-476_hmn9lp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-527-hdrw9w": {
      "id": "Jardim_da_Amazônia-527_hdrw9w",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-396-aesbzm": {
      "id": "Jardim_da_Amazônia-396_aesbzm",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-465-wwnsh7": {
      "id": "Jardim_da_Amazônia-465_wwnsh7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-444-fk0s5n": {
      "id": "Jardim_da_Amazônia-444_fk0s5n",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-462-fyacms": {
      "id": "Jardim_da_Amazônia-462_fyacms",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-211-qvgxip": {
      "id": "Jardim_da_Amazônia-211_qvgxip",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-287-fklaqd": {
      "id": "Jardim_da_Amazônia-287_fklaqd",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-323-vs32ru": {
      "id": "Jardim_da_Amazônia-323_vs32ru",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-322-zfxyux": {
      "id": "Jardim_da_Amazônia-322_zfxyux",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-173-hczwfd": {
      "id": "Jardim_da_Amazônia-173_hczwfd",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-165-auxccl": {
      "id": "Jardim_da_Amazônia-165_auxccl",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-131-trfay9": {
      "id": "Jardim_da_Amazônia-131_trfay9",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-161-jacpvt": {
      "id": "Jardim_da_Amazônia-161_jacpvt",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-88-jmrava": {
      "id": "Jardim_da_Amazônia-88_jmrava",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-70-catuwk": {
      "id": "Jardim_da_Amazônia-70_catuwk",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-46-yiqzyy": {
      "id": "Jardim_da_Amazônia-46_yiqzyy",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-124-nwdfck": {
      "id": "Jardim_da_Amazônia-124_nwdfck",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-25-rheaug": {
      "id": "Jardim_da_Amazônia-25_rheaug",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1-nmhh66": {
      "id": "Jardim_da_Amazônia-1_nmhh66",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-40-adu4mj": {
      "id": "Jardim_da_Amazônia-40_adu4mj",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-16-soj6ut": {
      "id": "Jardim_da_Amazônia-16_soj6ut",
      "photographer": "Marlon Erthal"
    },
    "jardim-6-jvgxqs": "jardim-6_jvgxqs",
    "jardim-hero-wi8rlu": "jardim-hero_wi8rlu",
    "jardim-5-wukn6z": "jardim-5_wukn6z",
    "jardim-4-ky0akd": "jardim-4_ky0akd",
    "jardim-3-p58x8k": "jardim-3_p58x8k",
    "jardim-1-m4axtr": "jardim-1_m4axtr",
    "jardim-2-ukfgpv": "jardim-2_ukfgpv"
  },
  "safari-boat": {
    "manoel-ruedi-26-ijhdjx": {
      "id": "MANOEL_RUEDI_26_ijhdjx",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-11-qnke5s": {
      "id": "MANOEL_RUEDI_11_qnke5s",
      "photographer": "Manoel Ruedi"
    },
    "manoel-ruedi-5-cfqngq": {
      "id": "MANOEL_RUEDI_5_cfqngq",
      "photographer": "Manoel Ruedi"
    },
    "safari-rio-claro-rdbh6e": {
      "id": "safari-rio-claro_rdbh6e",
      "photographer": "Kiko Silva"
    },
    "safari-boat-rvnwku": "safari-boat_rvnwku",
    "fogo-no-rio-u7cpws": "fogo-no-rio_u7cpws",
    "pirarucu-nwglqt": "pirarucu_nwglqt",
    "sucuri-a6v6wa": "sucuri_a6v6wa",
    "pintado-tdk4hx": "pintado_tdk4hx"
  },
  "caio-gimenez": {
    "fotos-caio-gimenez-14-tamkgp": "fotos_CAIO_GIMENEZ_14_tamkgp",
    "fotos-caio-gimenez-9-zmy748": "fotos_CAIO_GIMENEZ_9_zmy748",
    "fotos-caio-gimenez-7-voywr2": "fotos_CAIO_GIMENEZ_7_voywr2"
  },
  "gastronomia": {
    "doce-leite-queijo-eg8sll": "doce_leite_queijo_eg8sll",
    "favo-mel-kkda7i": "favo_mel_kkda7i",
    "banana-pao-k0q5kh": "banana_pao_k0q5kh",
    "chef-cozinhando-rfoeqp": "chef_cozinhando_rfoeqp",
    "chef-descontraido-vdjivu": "chef_descontraido_vdjivu",
    "mamao-tiw3en": "mamao_tiw3en",
    "pratos-eventos-kmvpv9": "pratos_eventos_kmvpv9",
    "pitanga2-nfitun": "pitanga2_nfitun",
    "pitanga-frbvmq": "pitanga_frbvmq",
    "jardim-da-amazônia-3973-reeybs": {
      "id": "Jardim_da_Amazônia-3973_reeybs",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3968-b7twku": {
      "id": "Jardim_da_Amazônia-3968_b7twku",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3979-eupwqn": {
      "id": "Jardim_da_Amazônia-3979_eupwqn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3951-xprm3b": {
      "id": "Jardim_da_Amazônia-3951_xprm3b",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3930-ehdgyr": {
      "id": "Jardim_da_Amazônia-3930_ehdgyr",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2851-pxct4y": {
      "id": "Jardim_da_Amazônia-2851_pxct4y",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2855-asjqeo": {
      "id": "Jardim_da_Amazônia-2855_asjqeo",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2773-onyyex": {
      "id": "Jardim_da_Amazônia-2773_onyyex",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2734-bphqht": {
      "id": "Jardim_da_Amazônia-2734_bphqht",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2647-kfpuvy": {
      "id": "Jardim_da_Amazônia-2647_kfpuvy",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2790-wgu7st": {
      "id": "Jardim_da_Amazônia-2790_wgu7st",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2678-ctqe0i": {
      "id": "Jardim_da_Amazônia-2678_ctqe0i",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2672-v2dxav": {
      "id": "Jardim_da_Amazônia-2672_v2dxav",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2702-geb69g": {
      "id": "Jardim_da_Amazônia-2702_geb69g",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2715-zd3gkp": {
      "id": "Jardim_da_Amazônia-2715_zd3gkp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2322-ojurek": {
      "id": "Jardim_da_Amazônia-2322_ojurek",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2151-ef33v2": {
      "id": "Jardim_da_Amazônia-2151_ef33v2",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2163-ot8lou": {
      "id": "Jardim_da_Amazônia-2163_ot8lou",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2228-r9pbpp": {
      "id": "Jardim_da_Amazônia-2228_r9pbpp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2184-xmban4": {
      "id": "Jardim_da_Amazônia-2184_xmban4",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2114-yid2nu": {
      "id": "Jardim_da_Amazônia-2114_yid2nu",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2121-ue381w": {
      "id": "Jardim_da_Amazônia-2121_ue381w",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2109-uwr6tc": {
      "id": "Jardim_da_Amazônia-2109_uwr6tc",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2147-vh5yif": {
      "id": "Jardim_da_Amazônia-2147_vh5yif",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2094-kvrs1j": {
      "id": "Jardim_da_Amazônia-2094_kvrs1j",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2093-ngc2d3": {
      "id": "Jardim_da_Amazônia-2093_ngc2d3",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2106-oyuwzl": {
      "id": "Jardim_da_Amazônia-2106_oyuwzl",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2103-bvqibu": {
      "id": "Jardim_da_Amazônia-2103_bvqibu",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1950-dy3egl": {
      "id": "Jardim_da_Amazônia-1950_dy3egl",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2017-ptlfct": {
      "id": "Jardim_da_Amazônia-2017_ptlfct",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1993-ioq4ib": {
      "id": "Jardim_da_Amazônia-1993_ioq4ib",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2091-ssbrx9": {
      "id": "Jardim_da_Amazônia-2091_ssbrx9",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2026-jflwb2": {
      "id": "Jardim_da_Amazônia-2026_jflwb2",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2042-qfg1hz": {
      "id": "Jardim_da_Amazônia-2042_qfg1hz",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2066-svxizk": {
      "id": "Jardim_da_Amazônia-2066_svxizk",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1987-opehjq": {
      "id": "Jardim_da_Amazônia-1987_opehjq",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2050-jhzvvu": {
      "id": "Jardim_da_Amazônia-2050_jhzvvu",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1970-o1osvy": {
      "id": "Jardim_da_Amazônia-1970_o1osvy",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1963-mppzep": {
      "id": "Jardim_da_Amazônia-1963_mppzep",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2081-ghp35o": {
      "id": "Jardim_da_Amazônia-2081_ghp35o",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1954-t45rgc": {
      "id": "Jardim_da_Amazônia-1954_t45rgc",
      "photographer": "Marlon Erthal"
    },
    "gatronomia-hero-kqnp26": "gatronomia-hero_kqnp26",
    "ocasioes-especiais-h44ve8": "ocasioes-especiais_h44ve8",
    "pratos-tipicos-f4meuw": "pratos-tipicos_f4meuw",
    "sobremesas-especiais-osgafx": "sobremesas-especiais_osgafx",
    "pratos-casa-kzicyc": "pratos-casa_kzicyc",
    "prato-especial-do-chef-w8ep8h": "prato-especial-do-chef_w8ep8h",
    "especiarias-regionais-vutp3x": "especiarias-regionais_vutp3x",
    "chef-jose-sergio-t9kpgf": "chef-jose-sergio_t9kpgf",
    "cafe-da-manha-frcj7z": "cafe-da-manha_frcj7z",
    "cafe-da-manha-ivpcds": "cafe-da-manha_ivpcds",
    "almoco-w6ysc9": "almoco_w6ysc9",
    "team-building-ucasc2": "team-building_ucasc2",
    "eventos-especiais-wkym1o": "eventos-especiais_wkym1o"
  },
  "espaco-convivencia": {
    "convivencia-iiz9ta": "convivencia_iiz9ta",
    "convivencia-mszyzh": "convivencia_mszyzh",
    "jardim-da-amazônia-3889-kwvmek": {
      "id": "Jardim_da_Amazônia-3889_kwvmek",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3902-nimzuq": {
      "id": "Jardim_da_Amazônia-3902_nimzuq",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3906-g3tysn": {
      "id": "Jardim_da_Amazônia-3906_g3tysn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3868-wajqd7": {
      "id": "Jardim_da_Amazônia-3868_wajqd7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3866-fynvrf": {
      "id": "Jardim_da_Amazônia-3866_fynvrf",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3857-zmcnxe": {
      "id": "Jardim_da_Amazônia-3857_zmcnxe",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3860-lfxzu5": {
      "id": "Jardim_da_Amazônia-3860_lfxzu5",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3851-hpelww": {
      "id": "Jardim_da_Amazônia-3851_hpelww",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3815-tab8g7": {
      "id": "Jardim_da_Amazônia-3815_tab8g7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3031-xq0nne": {
      "id": "Jardim_da_Amazônia-3031_xq0nne",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3104-l5sym6": {
      "id": "Jardim_da_Amazônia-3104_l5sym6",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3035-lrrhb7": {
      "id": "Jardim_da_Amazônia-3035_lrrhb7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3004-h8t0ue": {
      "id": "Jardim_da_Amazônia-3004_h8t0ue",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2965-ty8viw": {
      "id": "Jardim_da_Amazônia-2965_ty8viw",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2951-ylmylh": {
      "id": "Jardim_da_Amazônia-2951_ylmylh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3145-yetojo": {
      "id": "Jardim_da_Amazônia-3145_yetojo",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2943-fbaa8t": {
      "id": "Jardim_da_Amazônia-2943_fbaa8t",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2938-z7ow2k": {
      "id": "Jardim_da_Amazônia-2938_z7ow2k",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3014-dotnjn": {
      "id": "Jardim_da_Amazônia-3014_dotnjn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3009-waduyf": {
      "id": "Jardim_da_Amazônia-3009_waduyf",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2973-gguuum": {
      "id": "Jardim_da_Amazônia-2973_gguuum",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3097-jsnx41": {
      "id": "Jardim_da_Amazônia-3097_jsnx41",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2995-kn2ttm": {
      "id": "Jardim_da_Amazônia-2995_kn2ttm",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2923-r22dps": {
      "id": "Jardim_da_Amazônia-2923_r22dps",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1504-dvial4": {
      "id": "Jardim_da_Amazônia-1504_dvial4",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1929-eq81kp": {
      "id": "Jardim_da_Amazônia-1929_eq81kp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1923-xdjjd3": {
      "id": "Jardim_da_Amazônia-1923_xdjjd3",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1946-pnjuhp": {
      "id": "Jardim_da_Amazônia-1946_pnjuhp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1626-rmqvne": {
      "id": "Jardim_da_Amazônia-1626_rmqvne",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1541-dklvoh": {
      "id": "Jardim_da_Amazônia-1541_dklvoh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1586-jkg0tp": {
      "id": "Jardim_da_Amazônia-1586_jkg0tp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1416-bnctec": {
      "id": "Jardim_da_Amazônia-1416_bnctec",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1520-zv5sxv": {
      "id": "Jardim_da_Amazônia-1520_zv5sxv",
      "photographer": "Marlon Erthal"
    },
    "area-externa-ml9enj": "area-externa_ml9enj",
    "reuniao-executiva-ouxhas": "reuniao-executiva_ouxhas",
    "convivencia-p3tve7": "convivencia_p3tve7"
  },
  "familia": {
    "carmelita-xvyskv": "carmelita_xvyskv",
    "almor-peixe-esxwq5": "almor_peixe_esxwq5",
    "jardim-da-amazônia-3401-hqansh": {
      "id": "Jardim_da_Amazônia-3401_hqansh",
      "photographer": "Marlon Erthal"
    },
    "almor-carmelita-tx0knl": "almor-carmelita_tx0knl"
  },
  "almazonia": {
    "chef-almazonia-raqfqv": "chef_almazonia_raqfqv"
  },
  "bangalo-familia": {
    "familia-bangalo-bjbw93": "familia_bangalo_bjbw93",
    "jardim-da-amazônia-3773-c2beq5": {
      "id": "Jardim_da_Amazônia-3773_c2beq5",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1106-ws1fz1": {
      "id": "Jardim_da_Amazônia-1106_ws1fz1",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1103-ce7asj": {
      "id": "Jardim_da_Amazônia-1103_ce7asj",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1085-rba1ha": {
      "id": "Jardim_da_Amazônia-1085_rba1ha",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1144-oqujz8": {
      "id": "Jardim_da_Amazônia-1144_oqujz8",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-998-d5u8je": {
      "id": "Jardim_da_Amazônia-998_d5u8je",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-975-xljvb9": {
      "id": "Jardim_da_Amazônia-975_xljvb9",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-974-ngdj0e": {
      "id": "Jardim_da_Amazônia-974_ngdj0e",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1165-ztchyn": {
      "id": "Jardim_da_Amazônia-1165_ztchyn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-886-sufkuh": {
      "id": "Jardim_da_Amazônia-886_sufkuh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-874-rlr5ee": {
      "id": "Jardim_da_Amazônia-874_rlr5ee",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-903-eejblv": {
      "id": "Jardim_da_Amazônia-903_eejblv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-863-pmur7v": {
      "id": "Jardim_da_Amazônia-863_pmur7v",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-856-zmzgrh": {
      "id": "Jardim_da_Amazônia-856_zmzgrh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-948-cdpznn": {
      "id": "Jardim_da_Amazônia-948_cdpznn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-928-h6umcl": {
      "id": "Jardim_da_Amazônia-928_h6umcl",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-943-h7aono": {
      "id": "Jardim_da_Amazônia-943_h7aono",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-807-ftxvoa": {
      "id": "Jardim_da_Amazônia-807_ftxvoa",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-783-hdy895": {
      "id": "Jardim_da_Amazônia-783_hdy895",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-756-qpla99": {
      "id": "Jardim_da_Amazônia-756_qpla99",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-846-kfad7n": {
      "id": "Jardim_da_Amazônia-846_kfad7n",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-741-jmqxzv": {
      "id": "Jardim_da_Amazônia-741_jmqxzv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-726-frmxvo": {
      "id": "Jardim_da_Amazônia-726_frmxvo",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-693-jdjx3p": {
      "id": "Jardim_da_Amazônia-693_jdjx3p",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-681-sukkba": {
      "id": "Jardim_da_Amazônia-681_sukkba",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-675-ppk2tk": {
      "id": "Jardim_da_Amazônia-675_ppk2tk",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-744-solrly": {
      "id": "Jardim_da_Amazônia-744_solrly",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-717-atwotr": {
      "id": "Jardim_da_Amazônia-717_atwotr",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-751-svnptn": {
      "id": "Jardim_da_Amazônia-751_svnptn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-661-bavv7b": {
      "id": "Jardim_da_Amazônia-661_bavv7b",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-651-s9d3lw": {
      "id": "Jardim_da_Amazônia-651_s9d3lw",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-665-xecad1": {
      "id": "Jardim_da_Amazônia-665_xecad1",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-663-mbospc": {
      "id": "Jardim_da_Amazônia-663_mbospc",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-628-so2gho": {
      "id": "Jardim_da_Amazônia-628_so2gho",
      "photographer": "Marlon Erthal"
    },
    "familia-5-qtabos": "familia-5_qtabos",
    "familia-4-ubeuml": "familia-4_ubeuml",
    "familia-hero-tjgrgj": "familia-hero_tjgrgj",
    "familia-6-e1wlfl": "familia-6_e1wlfl",
    "familia-2-u3gvx4": "familia-2_u3gvx4",
    "familia-3-iytw3g": "familia-3_iytw3g",
    "familia-1-vdrp6r": "familia-1_vdrp6r"
  },
  "casa-fazenda": {
    "7-fazenda-trcjwp": "7_fazenda_trcjwp",
    "casa-fazenda-s1n8vm": "casa_fazenda_s1n8vm",
    "jardim-da-amazônia-1856-tv7mhd": {
      "id": "Jardim_da_Amazônia-1856_tv7mhd",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1835-rpts1w": {
      "id": "Jardim_da_Amazônia-1835_rpts1w",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1830-nkccqw": {
      "id": "Jardim_da_Amazônia-1830_nkccqw",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1824-l7jmr7": {
      "id": "Jardim_da_Amazônia-1824_l7jmr7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1733-nnn1ti": {
      "id": "Jardim_da_Amazônia-1733_nnn1ti",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1770-g4lktm": {
      "id": "Jardim_da_Amazônia-1770_g4lktm",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1754-njecru": {
      "id": "Jardim_da_Amazônia-1754_njecru",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1731-bdkq6u": {
      "id": "Jardim_da_Amazônia-1731_bdkq6u",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1812-yti77l": {
      "id": "Jardim_da_Amazônia-1812_yti77l",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1810-kcyvcj": {
      "id": "Jardim_da_Amazônia-1810_kcyvcj",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1802-pvsd2n": {
      "id": "Jardim_da_Amazônia-1802_pvsd2n",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1748-gzhe8v": {
      "id": "Jardim_da_Amazônia-1748_gzhe8v",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1707-o4s4u5": {
      "id": "Jardim_da_Amazônia-1707_o4s4u5",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1677-cygtxz": {
      "id": "Jardim_da_Amazônia-1677_cygtxz",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1723-q4wteu": {
      "id": "Jardim_da_Amazônia-1723_q4wteu",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1720-b3lxe8": {
      "id": "Jardim_da_Amazônia-1720_b3lxe8",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1674-djpiua": {
      "id": "Jardim_da_Amazônia-1674_djpiua",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1277-cfbiu3": {
      "id": "Jardim_da_Amazônia-1277_cfbiu3",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1290-bpzs5j": {
      "id": "Jardim_da_Amazônia-1290_bpzs5j",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1256-e2bi2l": {
      "id": "Jardim_da_Amazônia-1256_e2bi2l",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1288-lzebaa": {
      "id": "Jardim_da_Amazônia-1288_lzebaa",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1217-x4vuyi": {
      "id": "Jardim_da_Amazônia-1217_x4vuyi",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1205-dfplxq": {
      "id": "Jardim_da_Amazônia-1205_dfplxq",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1199-tear44": {
      "id": "Jardim_da_Amazônia-1199_tear44",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1185-kyywqy": {
      "id": "Jardim_da_Amazônia-1185_kyywqy",
      "photographer": "Marlon Erthal"
    },
    "6fazenda-4-ncigri": "6fazenda-4_ncigri",
    "7fazenda-3-gnrzph": "7fazenda-3_gnrzph",
    "7fazenda-1-ceheai": "7fazenda-1_ceheai",
    "7fazenda-2-rmxj02": "7fazenda-2_rmxj02",
    "6fazenda-5-jjgbpg": "6fazenda-5_jjgbpg",
    "6fazenda-1-oxbxca": "6fazenda-1_oxbxca",
    "6fazenda-2-uumffp": "6fazenda-2_uumffp",
    "7fazenda-4-tcprqe": "7fazenda-4_tcprqe",
    "6fazenda-3-dhnwre": "6fazenda-3_dhnwre",
    "5fazenda-3-vhejif": "5fazenda-3_vhejif",
    "5fazenda-4-rdubmo": "5fazenda-4_rdubmo",
    "5fazenda-5-jpx0xg": "5fazenda-5_jpx0xg",
    "5fazenda-2-olpvv7": "5fazenda-2_olpvv7",
    "5fazenda-1-wguam6": "5fazenda-1_wguam6"
  },
  "jardim-e-lagoas": {
    "jardim-da-amazônia-3829-hauhip": {
      "id": "Jardim_da_Amazônia-3829_hauhip",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3833-wmmlqx": {
      "id": "Jardim_da_Amazônia-3833_wmmlqx",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3848-w9t3g7": {
      "id": "Jardim_da_Amazônia-3848_w9t3g7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3817-mbuicj": {
      "id": "Jardim_da_Amazônia-3817_mbuicj",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3806-tg99vp": {
      "id": "Jardim_da_Amazônia-3806_tg99vp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3803-oronyh": {
      "id": "Jardim_da_Amazônia-3803_oronyh",
      "photographer": "Marlon Erthal"
    },
    "kiko-a7tmas": "kiko_a7tmas",
    "lagoa-uaetsf": "lagoa_uaetsf"
  },
  "casa-da-mata": {
    "jardim-da-amazônia-3751-wmnb8x": {
      "id": "Jardim_da_Amazônia-3751_wmnb8x",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3740-alyqg2": {
      "id": "Jardim_da_Amazônia-3740_alyqg2",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3731-qtmjco": {
      "id": "Jardim_da_Amazônia-3731_qtmjco",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3707-rt8aww": {
      "id": "Jardim_da_Amazônia-3707_rt8aww",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3714-fhy2ki": {
      "id": "Jardim_da_Amazônia-3714_fhy2ki",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3676-hn0sbn": {
      "id": "Jardim_da_Amazônia-3676_hn0sbn",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3652-dfxemv": {
      "id": "Jardim_da_Amazônia-3652_dfxemv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3639-osmlhh": {
      "id": "Jardim_da_Amazônia-3639_osmlhh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3690-ejddqg": {
      "id": "Jardim_da_Amazônia-3690_ejddqg",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3635-pxeunv": {
      "id": "Jardim_da_Amazônia-3635_pxeunv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3626-lfykt4": {
      "id": "Jardim_da_Amazônia-3626_lfykt4",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3682-kcblsn": {
      "id": "Jardim_da_Amazônia-3682_kcblsn",
      "photographer": "Marlon Erthal"
    },
    "mata-6-jeycpm": "mata-6_jeycpm",
    "mata-hero-lfg1g8": "mata-hero_lfg1g8",
    "mata-5-prf7pi": "mata-5_prf7pi",
    "mata-2-sdizvm": "mata-2_sdizvm",
    "mata-4-dlcx9i": "mata-4_dlcx9i",
    "mata-1-pbggfy": "mata-1_pbggfy",
    "mata-3-jzdaaz": "mata-3_jzdaaz"
  },
  "piscina": {
    "jardim-da-amazônia-3608-k2fvfu": {
      "id": "Jardim_da_Amazônia-3608_k2fvfu",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3602-sisbpp": {
      "id": "Jardim_da_Amazônia-3602_sisbpp",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3588-surdeb": {
      "id": "Jardim_da_Amazônia-3588_surdeb",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3538-kppv8a": {
      "id": "Jardim_da_Amazônia-3538_kppv8a",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3495-em7syh": {
      "id": "Jardim_da_Amazônia-3495_em7syh",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3471-yxyety": {
      "id": "Jardim_da_Amazônia-3471_yxyety",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3434-jsn8sr": {
      "id": "Jardim_da_Amazônia-3434_jsn8sr",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3427-jshmzs": {
      "id": "Jardim_da_Amazônia-3427_jshmzs",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3333-fqs03v": {
      "id": "Jardim_da_Amazônia-3333_fqs03v",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3377-jzsf4a": {
      "id": "Jardim_da_Amazônia-3377_jzsf4a",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3424-u8a4an": {
      "id": "Jardim_da_Amazônia-3424_u8a4an",
      "photographer": "Marlon Erthal"
    },
    "xrlenerulep6ismgn8yx": "xrlenerulep6ismgn8yx",
    "piscina-knlf8d": "piscina_knlf8d"
  },
  "trilhas": {
    "jardim-da-amazônia-3234-h1mjwq": {
      "id": "Jardim_da_Amazônia-3234_h1mjwq",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3216-yrohd0": {
      "id": "Jardim_da_Amazônia-3216_yrohd0",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3238-fb5k3w": {
      "id": "Jardim_da_Amazônia-3238_fb5k3w",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3188-lceiu2": {
      "id": "Jardim_da_Amazônia-3188_lceiu2",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3166-npmlu7": {
      "id": "Jardim_da_Amazônia-3166_npmlu7",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3179-xym08p": {
      "id": "Jardim_da_Amazônia-3179_xym08p",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3266-mbiwmz": {
      "id": "Jardim_da_Amazônia-3266_mbiwmz",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3256-h9yfjv": {
      "id": "Jardim_da_Amazônia-3256_h9yfjv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3124-c16uor": {
      "id": "Jardim_da_Amazônia-3124_c16uor",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3116-fr02zg": {
      "id": "Jardim_da_Amazônia-3116_fr02zg",
      "photographer": "Marlon Erthal"
    },
    "trilhas-hero-qjfilt": "trilhas-hero_qjfilt",
    "trilhas-pafxet": "trilhas_pafxet"
  },
  "casa-buriti": {
    "jardim-da-amazônia-3064-ioy1yc": {
      "id": "Jardim_da_Amazônia-3064_ioy1yc",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-3051-hakgyq": {
      "id": "Jardim_da_Amazônia-3051_hakgyq",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2893-rilh9v": {
      "id": "Jardim_da_Amazônia-2893_rilh9v",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2914-kqx6ka": {
      "id": "Jardim_da_Amazônia-2914_kqx6ka",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-1311-a4n69x": {
      "id": "Jardim_da_Amazônia-1311_a4n69x",
      "photographer": "Marlon Erthal"
    }
  },
  "cafe-da-manha": {
    "jardim-da-amazônia-2795-cq11f5": {
      "id": "Jardim_da_Amazônia-2795_cq11f5",
      "photographer": "Marlon Erthal"
    }
  },
  "chef": {
    "jardim-da-amazônia-2562-sljimb": {
      "id": "Jardim_da_Amazônia-2562_sljimb",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2312-ijgnj3": {
      "id": "Jardim_da_Amazônia-2312_ijgnj3",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2305-vmwmgv": {
      "id": "Jardim_da_Amazônia-2305_vmwmgv",
      "photographer": "Marlon Erthal"
    },
    "jardim-da-amazônia-2277-ddmig6": {
      "id": "Jardim_da_Amazônia-2277_ddmig6",
      "photographer": "Marlon Erthal"
    }
  },
  "uncategorized": {
    "acomodacoes-ghqhpo": "acomodacoes_ghqhpo",
    "familia-fogueira-xch5l3": "familia-fogueira_xch5l3"
  },
  "press": {
    "camalote-pagina3-af3v7z": "camalote-pagina3_af3v7z",
    "wonderlust-2-wn6zao": "wonderlust-2_wn6zao",
    "wonderlust-alvd9p": "wonderlust_alvd9p",
    "camalote-pagina2-ep900h": "camalote-pagina2_ep900h",
    "wonderlust-1-cnm4vl": "wonderlust-1_cnm4vl",
    "camalote-pagina1-dz0ek4": "camalote-pagina1_dz0ek4",
    "camalote-capa-xaeyct": "camalote-capa_xaeyct"
  },
  "documentos": {
    "hero": "documentos_hero_default"
  }
};

export function getFolderImage(folder, imageName, transformations = DEFAULT_TRANSFORMATIONS) {
  // Check if folder and image exist in mapping
  if (!folderImageMapping[folder] || !folderImageMapping[folder][imageName]) {
    console.warn(`Image not found: ${folder}/${imageName}`);
    return null;
  }

  // Get the image ID or object from the mapping
  const imageData = folderImageMapping[folder][imageName];
  
  // If it's an object with an id property, use that ID
  const imageId = typeof imageData === 'object' && imageData.id ? imageData.id : imageData;

  // Return the full URL with transformations
  return `${CLOUDINARY_BASE_URL}/${transformations}/${imageId}`;
}

export function getImagePhotographer(folder, imageName) {
  const mapping = folderImageMapping[folder];
  if (!mapping) return undefined;

  const imageData = mapping[imageName];
  if (!imageData || typeof imageData === 'string') return undefined;

  return imageData.photographer;
}

// Add this function to optimize image URLs for better LCP
export function getOptimizedUrl(url, { width = 1200, quality = 'auto:good' } = {}) {
  if (!url) return '';
  
  // Check if it's a Cloudinary URL
  if (url.includes('cloudinary.com')) {
    return url.replace(
      '/upload/',
      `/upload/c_scale,w_${width},f_auto,q_${quality}/`
    );
  }
  
  return url;
}

// Enhanced version of getFolderImage with LCP optimization
export function getOptimizedFolderImage(folder, imageName, options = {}) {
  const { width = 1200, quality = 'auto:good' } = options;
  
  // Get the basic image URL
  const baseUrl = getFolderImage(folder, imageName, '');
  
  if (!baseUrl) return null;
  
  // Apply optimizations
  return `${CLOUDINARY_BASE_URL}/c_scale,w_${width},f_auto,q_${quality}/${baseUrl.split('/').pop()}`;
}
