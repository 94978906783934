import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// PDF Document Component
const MenuPDF = () => (
  <Document title="Cardápio Jardim Amazônia">
    <Page size="A4" style={{
      padding: 50,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Header with Logo */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        paddingBottom: 10,
      }}>
        <PDFImage src="/Logo Jd Amazonia.png" style={{
          width: 140,
          height: 70,
          objectFit: 'contain',
        }} />
      </View>
      
      {/* Title */}
      <Text style={{
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#4b6455',
      }}>Cardápio</Text>
      
      {/* Menu Content */}
      <View style={{
        fontSize: 12,
        lineHeight: 1.6,
        marginBottom: 40,
      }}>
        {/* Appetizers */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Entradas</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Bruschetta da Casa</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Fatias de pão italiano com tomate, manjericão, alho e azeite extra virgem</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Sopa de Peixe</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Caldo leve de peixe com legumes regionais e ervas</Text>
        </View>
        
        {/* Main Courses */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Pratos Principais</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Peixe da Amazônia Grelhado</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Peixe fresco grelhado com ervas aromáticas, acompanhado de arroz com tucupi e farofa de banana</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Risoto de Cogumelos Selvagens</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Arroz arbóreo cremoso com cogumelos da região e queijo parmesão</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Moqueca de Peixes</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Tradicional ensopado de peixes com leite de coco, dendê, pimentões e coentro</Text>
        </View>
        
        {/* Desserts */}
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
        }}>Sobremesas</Text>
        
        <View>
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Pudim de Castanha-do-Pará</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Pudim cremoso de castanhas amazônicas com calda de caramelo</Text>
          
          <Text style={{ fontWeight: 'bold', marginBottom: 5 }}>Mousse de Cupuaçu</Text>
          <Text style={{ fontSize: 10, marginBottom: 10 }}>Mousse leve e refrescante de cupuaçu com raspas de chocolate</Text>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1pt solid #4b6455',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
    </Page>
  </Document>
);

function Menu({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-3xl mx-auto">
      <PDFPreview 
        document={<MenuPDF />} 
        fileName="cardapio-jardim-amazonia.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default Menu; 