import React, { useState } from 'react';
import Menu from '../documents/Menu';
import WineList from '../documents/WineList';
import Receptivo from '../documents/Receptivo';
import Cestas from '../documents/Cestas';
import GoogleReviews from '../documents/GoogleReviews';
import TarifarioEventos from '../documents/TarifarioEventos';
import MenuQRCode from '../documents/MenuQRCode';
import RoteiroJardim from '../documents/RoteiroJardim';
import ReactPDF from '../documents/ReactPDF';
import { FaChevronDown, FaChevronUp } from 'react-icons/fa';
import heroImage from '../documents/fotos_roteiro/aereo.jpeg';

function Documentos() {
  const [expandedDoc, setExpandedDoc] = useState(null);
  // Use constant since we never change this value
  const pdfPreviewMode = false;
  
  // Use the imported image
  const heroStyle = {
    backgroundImage: `url(${heroImage})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  };
  
  const toggleExpand = (docId) => {
    if (expandedDoc === docId) {
      setExpandedDoc(null);
    } else {
      setExpandedDoc(docId);
    }
  };
  
  // Document definitions
  const documents = [
    {
      id: 'official',
      title: 'Documentos Oficiais',
      description: 'Crie documentos oficiais do Jardim Amazônia Lodge com formatação padronizada.',
      component: <ReactPDF pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'menu',
      title: 'Cardápio',
      description: 'Cardápio completo do Jardim Amazônia Lodge.',
      component: <Menu pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'menu-qr',
      title: 'Menu Interativo - QR Code',
      description: 'Gere um QR Code para acesso digital ao cardápio.',
      component: <MenuQRCode pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'wine',
      title: 'Carta de Vinhos',
      description: 'Nossa seleção especial de vinhos para harmonizar com a gastronomia local.',
      component: <WineList pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'receptivo',
      title: 'Informações de Boas-vindas',
      description: 'Informações essenciais para sua chegada e estadia.',
      component: <Receptivo pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'cestas',
      title: 'Cestas de Piquenique',
      description: 'Opções de cestas para piqueniques e momentos especiais.',
      component: <Cestas pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'roteiro',
      title: 'Roteiro Jardim Amazônia',
      description: 'Roteiro completo de atividades e experiências no Jardim Amazônia.',
      component: <RoteiroJardim pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'tarifario',
      title: 'Tarifário de Eventos',
      description: 'Informações sobre tarifas e opções para realização de eventos.',
      component: <TarifarioEventos pdfPreviewMode={pdfPreviewMode} />
    },
    {
      id: 'reviews',
      title: 'Que tal um café por sua opinião?',
      description: 'QR Code para avaliações no Google e informações para os hóspedes.',
      component: <GoogleReviews pdfPreviewMode={pdfPreviewMode} />
    }
  ];
  
  return (
    <div className="min-h-screen bg-white">
      {/* Hero Section */}
      <section className="relative min-h-[40vh] bg-cover bg-center" style={heroStyle}>
        <div className="absolute inset-0 bg-black/60">
          <div className="container mx-auto px-4 h-full flex flex-col justify-center items-center text-center text-white">
            <h1 className="text-5xl md:text-6xl font-bold mb-4">Central de Documentos</h1>
            <p className="text-xl text-gray-200">Padrão para facilitar a operação</p>
          </div>
        </div>
      </section>

      {/* Documents Section */}
      <section className="py-16 bg-gray-50">
        <div className="container mx-auto px-4 max-w-4xl">
          <h2 className="text-3xl font-bold text-center mb-12">Documentos</h2>
          
          {/* Expandable Document List */}
          <div className="space-y-6">
            {documents.map((doc) => (
              <div 
                key={doc.id} 
                className={`
                  bg-white rounded-xl shadow-lg overflow-hidden transition-all duration-300
                  ${doc.id === 'official' ? 'border-2 border-primary' : ''}
                `}
              >
                {/* Document Header/Title Bar (always visible) */}
                <button 
                  onClick={() => toggleExpand(doc.id)}
                  className={`
                    w-full p-6 flex justify-between items-center transition-colors text-left
                    ${doc.id === 'official' ? 'bg-primary-light hover:bg-primary/10' : 'hover:bg-gray-50'}
                  `}
                >
                  <div>
                    <h3 className={`text-xl font-bold ${doc.id === 'official' ? 'text-primary' : 'text-primary'} mb-1`}>
                      {doc.title}
                    </h3>
                    <p className={`${doc.id === 'official' ? 'text-primary-dark' : 'text-gray-600'} mt-1`}>
                      {doc.description}
                    </p>
                  </div>
                  <div className={`${doc.id === 'official' ? 'text-primary-dark' : 'text-primary'}`}>
                    {expandedDoc === doc.id ? <FaChevronUp size={20} /> : <FaChevronDown size={20} />}
                  </div>
                </button>
                
                {/* Document Content (visible only when expanded) */}
                {expandedDoc === doc.id && (
                  <div className={`p-6 pt-0 ${doc.id === 'official' ? 'border-t border-primary/30' : 'border-t border-gray-100'}`}>
                    {doc.component}
                  </div>
                )}
              </div>
            ))}
          </div>
        </div>
      </section>
    </div>
  );
}

export default Documentos; 