import React, { useState, useEffect } from 'react';

const EmailUpdatePopup = () => {
  const [isOpen, setIsOpen] = useState(false); // Start closed to implement delay
  const [shouldShow, setShouldShow] = useState(true);

  useEffect(() => {
    // Check if the popup has been closed before
    const popupClosed = localStorage.getItem('emailPopupClosed');
    const closedDate = localStorage.getItem('emailPopupClosedDate');
    
    // If closed date exists, check if it's been less than 10 days
    if (closedDate) {
      const daysSinceClosed = (new Date() - new Date(closedDate)) / (1000 * 60 * 60 * 24);
      if (daysSinceClosed < 10) {
        setShouldShow(false);
        return;
      }
    }

    // If popup was previously closed or we have no record, prepare to show it
    setShouldShow(popupClosed !== 'true');
  }, []);

  // Add delay effect
  useEffect(() => {
    if (shouldShow) {
      const timer = setTimeout(() => {
        setIsOpen(true);
      }, 2000); // 2-second delay
      
      return () => clearTimeout(timer); // Cleanup timer
    }
  }, [shouldShow]);

  const closePopup = () => {
    setIsOpen(false);
    localStorage.setItem('emailPopupClosed', 'true');
    localStorage.setItem('emailPopupClosedDate', new Date().toISOString());
  };

  if (!isOpen || !shouldShow) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center">
      {/* Overlay */}
      <div className="absolute inset-0 bg-black bg-opacity-50" onClick={closePopup}></div>
      
      {/* Popup content - just the image with X button */}
      <div className="relative w-3/5 sm:w-2/5 md:w-1/3 lg:w-1/5 mx-auto">
        <img 
          src="/novos-contato.jpeg" 
          alt="Novos contatos de email" 
          className="w-full h-auto rounded-lg shadow-xl"
        />
        
        {/* Prominent X button */}
        <button 
          onClick={closePopup}
          className="absolute top-2 right-2 bg-black bg-opacity-50 hover:bg-opacity-70 text-white rounded-full p-2 transition-all"
          aria-label="Close"
          style={{ width: '28px', height: '28px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={3} d="M6 18L18 6M6 6l12 12" />
          </svg>
        </button>
      </div>
    </div>
  );
};

export default EmailUpdatePopup; 