import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// PDF Document Component
const ReceptivoPDF = () => (
  <Document title="Informações de Receptivo">
    <Page size="A4" style={{
      padding: 50,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Header with Logo */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        paddingBottom: 10,
      }}>
        <PDFImage src="/Logo Jd Amazonia.png" style={{
          width: 140,
          height: 70,
          objectFit: 'contain',
        }} />
      </View>
      
      {/* Title */}
      <Text style={{
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#4b6455',
      }}>Informações de Receptivo</Text>
      
      <Text style={{
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 30,
        fontStyle: 'italic',
        color: '#666',
      }}>
        Guia completo para sua estadia no Jardim da Amazônia
      </Text>
      
      {/* Content */}
      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Horários de Entrada e Saída</Text>
        
        <View style={{ marginBottom: 15 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Check-in: a partir das 14h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Check-out: até às 12h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Recepção: aberta das 07h00 às 22h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Serviço de concierge 24 horas</Text>
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Refeições</Text>
        
        <View style={{ marginBottom: 15 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Café da manhã: 07h30 às 09h30</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Almoço: 12h30 às 14h30</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Jantar: 19h30 às 21h30</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Bar: aberto das 11h00 às 23h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Pedidos especiais devem ser feitos com antecedência mínima de 3 horas</Text>
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Passeios e Atividades</Text>
        
        <View style={{ marginBottom: 15 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Trilhas guiadas: 09h00 e 15h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Passeio de barco: 10h00 e 16h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Observação de pássaros: 06h00 (mediante agendamento)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Pesca esportiva: 07h00 às 17h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Atividades noturnas: a partir das 20h00 (verificar programação)</Text>
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Informações Importantes</Text>
        
        <View style={{ marginBottom: 15 }}>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Wi-Fi disponível nas áreas comuns</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Sinal de celular limitado em algumas áreas</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Piscina natural: aberta das 08h00 às 18h00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Estacionamento gratuito</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Serviço de lavanderia disponível (custo adicional)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Medicamentos básicos disponíveis na recepção</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Serviço de transfer: consultar disponibilidade e valores</Text>
        </View>
      </View>
      
      {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 50,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
        color: '#666',
        fontStyle: 'italic',
      }}>Em caso de dúvidas, nossa equipe está sempre à disposição para auxiliá-lo.</Text>
      
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1pt solid #4b6455',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
    </Page>
  </Document>
);

function Receptivo({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-4xl mx-auto">
      <PDFPreview 
        document={<ReceptivoPDF />} 
        fileName="informacoes-receptivo.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default Receptivo; 