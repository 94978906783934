import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// Import images directly from local directory
import qrCodeSrc from './fotos_roteiro/QR Code - Menu.png';
import logoSrc from './fotos_roteiro/Logo Jd Amazonia.png';

// PDF Document Component
const MenuQRCodePDF = () => {
  return (
    <Document title="QR Codes do Cardápio">
      <Page size="LETTER" style={{
        padding: 0,
        backgroundColor: 'white',
        position: 'relative',
        fontFamily: 'Helvetica',
      }}>
        {/* QR Code 1 - Top Left */}
        <View style={{
          position: 'absolute',
          top: 10,
          left: 10,
          width: '48%',
          height: '48%',
          padding: 10,
          border: '1pt solid #eaeaea',
          backgroundColor: 'white',
        }}>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
            <PDFImage 
              src={qrCodeSrc}
              style={{
                width: 150,
                height: 150,
                marginBottom: 10,
              }}
            />
            <Text style={{
              fontSize: 12,
              fontWeight: 'bold',
              marginBottom: 5,
              textAlign: 'center',
            }}>
              Cardápio / Menu
            </Text>
            <PDFImage 
              src={logoSrc} 
              style={{
                width: 100,
                height: 100,
                marginTop: 10,
              }}
            />
          </View>
        </View>

        {/* QR Code 2 - Top Right */}
        <View style={{
          position: 'absolute',
          top: 10,
          right: 10,
          width: '48%',
          height: '48%',
          padding: 10,
          border: '1pt solid #eaeaea',
          backgroundColor: 'white',
        }}>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
            <PDFImage 
              src={qrCodeSrc}
              style={{
                width: 150,
                height: 150,
                marginBottom: 10,
              }}
            />
            <Text style={{
              fontSize: 12,
              fontWeight: 'bold',
              marginBottom: 5,
              textAlign: 'center',
            }}>
              Cardápio / Menu
            </Text>
            <PDFImage 
              src={logoSrc} 
              style={{
                width: 100,
                height: 100,
                marginTop: 10,
              }}
            />
          </View>
        </View>

        {/* QR Code 3 - Bottom Left */}
        <View style={{
          position: 'absolute',
          bottom: 10,
          left: 10,
          width: '48%',
          height: '48%',
          padding: 10,
          border: '1pt solid #eaeaea',
          backgroundColor: 'white',
        }}>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
            <PDFImage 
              src={qrCodeSrc}
              style={{
                width: 150,
                height: 150,
                marginBottom: 10,
              }}
            />
            <Text style={{
              fontSize: 12,
              fontWeight: 'bold',
              marginBottom: 5,
              textAlign: 'center',
            }}>
              Cardápio / Menu
            </Text>
            <PDFImage 
              src={logoSrc} 
              style={{
                width: 100,
                height: 100,
                marginTop: 10,
              }}
            />
          </View>
        </View>

        {/* QR Code 4 - Bottom Right */}
        <View style={{
          position: 'absolute',
          bottom: 10,
          right: 10,
          width: '48%',
          height: '48%',
          padding: 10,
          border: '1pt solid #eaeaea',
          backgroundColor: 'white',
        }}>
          <View style={{
            alignItems: 'center',
            justifyContent: 'center',
            height: '100%',
          }}>
            <PDFImage 
              src={qrCodeSrc}
              style={{
                width: 150,
                height: 150,
                marginBottom: 10,
              }}
            />
            <Text style={{
              fontSize: 12,
              fontWeight: 'bold',
              marginBottom: 5,
              textAlign: 'center',
            }}>
              Cardápio / Menu
            </Text>
            <PDFImage 
              src={logoSrc} 
              style={{
                width: 100,
                height: 100,
                marginTop: 10,
              }}
            />
          </View>
        </View>
      </Page>
    </Document>
  );
};

function MenuQRCode({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-4xl mx-auto">
      <PDFPreview 
        document={<MenuQRCodePDF />} 
        fileName="qrcodes-cardapio.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default MenuQRCode; 