import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// PDF Document Component
const TarifarioEventosPDF = () => (
  <Document title="Tarifário de Eventos e Serviços">
    <Page size="A4" style={{
      padding: 50,
      backgroundColor: 'white',
      fontFamily: 'Helvetica',
    }}>
      {/* Header with Logo */}
      <View style={{
        flexDirection: 'row',
        justifyContent: 'center',
        marginBottom: 20,
        paddingBottom: 10,
      }}>
        <PDFImage src="/Logo Jd Amazonia.png" style={{
          width: 140,
          height: 70,
          objectFit: 'contain',
        }} />
      </View>
      
      {/* Title */}
      <Text style={{
        fontSize: 24,
        textAlign: 'center',
        marginBottom: 20,
        color: '#4b6455',
      }}>Tarifário de Eventos e Serviços</Text>
      
      <Text style={{
        fontSize: 12,
        textAlign: 'center',
        marginBottom: 30,
        fontStyle: 'italic',
        color: '#666',
      }}>
        Nossas melhores ofertas para tornar o seu evento especial
      </Text>
      
      {/* Content */}
      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Eventos e Celebrações</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Casamento Completo</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Cerimônia em área exclusiva do jardim</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Recepção para até 80 pessoas</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Menu completo com buffet regional</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Decoração temática (flores amazônicas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• DJ e som ambiente (6 horas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5, fontWeight: 'bold' }}>Valor: a partir de R$ 22.000,00</Text>
        </View>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Aniversário Especial</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Área reservada para celebração</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Buffet completo para até 40 pessoas</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Bolo temático (opções amazônicas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Som ambiente (4 horas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5, fontWeight: 'bold' }}>Valor: a partir de R$ 8.500,00</Text>
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Reuniões Corporativas</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Pacote Empresarial Básico</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Sala de reuniões para até 20 pessoas</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Equipamentos audiovisuais</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Coffee break (2 turnos)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Almoço executivo</Text>
          <Text style={{ fontSize: 10, marginBottom: 5, fontWeight: 'bold' }}>Valor: a partir de R$ 4.200,00 (diária)</Text>
        </View>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Pacote Empresarial Premium</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Sala de reuniões para até 40 pessoas</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Equipamentos audiovisuais completos</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Coffee break (3 turnos)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Almoço executivo premium</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Atividade team building (2 horas)</Text>
          <Text style={{ fontSize: 10, marginBottom: 5, fontWeight: 'bold' }}>Valor: a partir de R$ 7.800,00 (diária)</Text>
        </View>
      </View>

      <View style={{ marginBottom: 20 }}>
        <Text style={{
          fontSize: 16,
          fontWeight: 'bold',
          marginBottom: 10,
          color: '#4b6455',
          borderBottom: '1pt solid #4b6455',
          paddingBottom: 5,
        }}>Serviços Adicionais</Text>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Decoração Personalizada</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Decoração temática completa: R$ 3.500,00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Arranjos florais especiais: a partir de R$ 1.200,00</Text>
        </View>
        
        <View style={{ marginBottom: 20 }}>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Serviços de Audiovisual</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• DJ profissional (4 horas): R$ 1.800,00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Projetor e tela grande: R$ 600,00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Sistema de som completo: R$ 1.200,00</Text>
        </View>

        <View>
          <Text style={{ fontSize: 12, fontWeight: 'bold', marginBottom: 5 }}>Recreação e Atividades</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Passeio guiado pelo jardim (grupo de 15): R$ 450,00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Observação noturna de estrelas (grupo de 10): R$ 650,00</Text>
          <Text style={{ fontSize: 10, marginBottom: 5 }}>• Workshop de culinária amazônica (grupo de 8): R$ 1.200,00</Text>
        </View>
      </View>

          {/* Footer */}
      <Text style={{
        position: 'absolute',
        bottom: 50,
        left: 0,
        right: 0,
        textAlign: 'center',
        fontSize: 10,
        color: '#666',
        fontStyle: 'italic',
      }}>Todos os valores estão sujeitos a alterações conforme a temporada e disponibilidade.</Text>
      
      <Text style={{
        position: 'absolute',
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        borderTop: '1pt solid #4b6455',
        paddingTop: 10,
        fontSize: 10,
        color: 'grey',
      }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
    </Page>
  </Document>
);

function TarifarioEventos({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-4xl mx-auto">
      <PDFPreview 
        document={<TarifarioEventosPDF />} 
        fileName="tarifario-eventos.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default TarifarioEventos; 