import { track } from '@vercel/analytics';
import { featureFlags } from '../config/siteConfig';

/**
 * Emits feature flags to the DOM to be picked up by Vercel Web Analytics
 * This allows analytics to enrich page views and events with feature flags
 */
export const emitFeatureFlagsToDOM = () => {
  // Get only the enabled feature flags
  const enabledFlags = Object.entries(featureFlags)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);
  
  // Create a script element to inject feature flags
  const script = document.createElement('script');
  script.id = 'vercel-feature-flags';
  script.type = 'application/json';
  script.textContent = JSON.stringify({ flags: enabledFlags });
  
  // Remove existing flags if any
  const existingScript = document.getElementById('vercel-feature-flags');
  if (existingScript) {
    existingScript.remove();
  }
  
  // Add the script to the document head
  document.head.appendChild(script);
};

/**
 * Track an event with current feature flags
 * @param {string} eventName - Name of the event to track
 * @param {object} properties - Additional properties to track with the event
 * @param {string[]} specificFlags - Specific flags to track (optional)
 */
export const trackWithFlags = (eventName, properties = {}, specificFlags = null) => {
  if (specificFlags) {
    // Use specific flags if provided
    track(eventName, properties, { flags: specificFlags });
  } else {
    // Otherwise use all enabled feature flags
    const enabledFlags = Object.entries(featureFlags)
      .filter(([_, value]) => value === true)
      .map(([key]) => key);
    
    track(eventName, properties, { flags: enabledFlags });
  }
};

/**
 * Get enabled feature flags for analytics
 * @returns {string[]} Array of enabled feature flag names
 */
export const getEnabledFeatureFlags = () => {
  return Object.entries(featureFlags)
    .filter(([_, value]) => value === true)
    .map(([key]) => key);
}; 