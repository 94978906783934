import React from 'react';
import { Document, Page, Text, View, Image as PDFImage } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// Import images directly from local directory
import qrCodeSrc from './fotos_roteiro/QR Code - Google Review.png';
import logoSrc from './fotos_roteiro/Logo Jd Amazonia.png';

// PDF Document Component
const GoogleReviewsPDF = () => {
  return (
    <Document title="Google Reviews QR Code">
      <Page size="A4" style={{
        padding: 50,
        backgroundColor: 'white',
        fontFamily: 'Helvetica',
      }}>
        {/* Content */}
        <View style={{
          flexDirection: 'row',
          justifyContent: 'space-between',
          marginBottom: 40,
          padding: 20,
        }}>
          {/* Left side with title */}
          <View style={{ 
            width: '45%', 
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <View style={{
              width: 120,
              height: 120,
              marginBottom: 20,
              justifyContent: 'center',
              alignItems: 'center',
              border: '1pt solid #eaeaea',
              borderRadius: 60,
              backgroundColor: '#f9f9f9',
            }}>
              <Text style={{
                fontSize: 24,
                color: '#4b6455',
                textAlign: 'center',
              }}>☕</Text>
              <Text style={{
                fontSize: 12,
                color: '#4b6455',
                textAlign: 'center',
                marginTop: 5,
              }}>CAFÉ</Text>
            </View>
            <Text style={{
              fontSize: 20,
              fontWeight: 'bold',
              marginBottom: 10,
              textAlign: 'center',
            }}>
              Que tal um café por sua opinião?
            </Text>
            <Text style={{
              fontSize: 12,
              fontStyle: 'italic',
              color: '#666',
              textAlign: 'center',
            }}>
              How about a coffee for your review?
            </Text>
          </View>
          
          {/* Right side with QR code */}
          <View style={{ 
            width: '45%', 
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <PDFImage 
              src={qrCodeSrc} 
              style={{
                width: 180,
                height: 180,
                marginBottom: 20,
              }}
            />
            <Text style={{
              fontSize: 12,
              marginBottom: 5,
              textAlign: 'center',
            }}>
              Escaneie o QR Code, compartilhe sua experiência e sirva um cafézinho!
            </Text>
            <Text style={{
              fontSize: 10,
              fontStyle: 'italic',
              color: '#666',
              textAlign: 'center',
            }}>
              Scan the QR Code, share your experience and get a coffee!
            </Text>
          </View>
        </View>
        
        {/* Logo at bottom */}
        <View style={{
          borderTop: '1pt solid #eaeaea',
          paddingTop: 20,
          alignItems: 'center',
        }}>
          <PDFImage 
            src={logoSrc} 
            style={{
              width: 120,
              height: 60,
              objectFit: 'contain',
            }}
          />
        </View>
        
        {/* Footer */}
        <Text style={{
          position: 'absolute',
          bottom: 30,
          left: 0,
          right: 0,
          textAlign: 'center',
          fontSize: 10,
          color: 'grey',
        }}>Jardim da Amazônia Lodge - DESDE 1997</Text>
      </Page>
    </Document>
  );
};

function GoogleReviews({ pdfPreviewMode = false }) {
  // Always show the PDF preview regardless of pdfPreviewMode
  return (
    <div className="max-w-4xl mx-auto">
      <PDFPreview 
        document={<GoogleReviewsPDF />} 
        fileName="google-reviews-qrcode.pdf"
        showPrintButton={true}
      />
    </div>
  );
}

export default GoogleReviews; 