// src/documents/ReactPDF.js
import React, { useState } from 'react';
import { Document, Page, Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import PDFPreview from '../components/PDFPreview';

// Import logo from the correct path within src directory
import logoSrc from './fotos_roteiro/Logo Jd Amazonia.png';

// Create styles for PDF
const styles = StyleSheet.create({
  page: {
    padding: 50,
    backgroundColor: 'white',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
    marginBottom: 20,
    paddingBottom: 10,
    borderBottom: '1pt solid #4b6455',
  },
  logo: {
    width: 140,
    height: 100,
    objectFit: 'contain',
  },
  title: {
    fontSize: 24,
    textAlign: 'center',
    marginBottom: 20,
    color: '#4b6455',
  },
  content: {
    fontSize: 12,
    lineHeight: 1.6,
    marginBottom: 40,
  },
  footer: {
    position: 'absolute',
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    borderTop: '1pt solid #4b6455',
    paddingTop: 10,
    fontSize: 10,
    color: 'grey',
  },
  signature: {
    marginTop: 30,
    fontSize: 12,
    textAlign: 'center',
  },
  signatureLine: {
    width: 150,
    borderBottom: '1pt solid #000',
    marginLeft: 'auto',
    marginRight: 'auto',
    marginBottom: 5,
  },
  signatureName: {
    marginTop: 5,
    fontSize: 12,
    textAlign: 'center',
  },
  signatureTitle: {
    marginTop: 5,
    fontSize: 10,
    textAlign: 'center',
  },
});

// Simple PDF Document Component
const OfficialDocument = ({ title, content, signatureName, signatureTitle }) => {
  return (
    <Document title={title || "Documento Oficial"}>
      <Page style={styles.page}>
        {/* Header */}
        <View style={styles.header}>
          <Image
            src={logoSrc}
            style={styles.logo}
          />
          <Text style={styles.title}>{title || "Documento Oficial"}</Text>
        </View>
        
        {/* Content */}
        <View style={styles.content}>
          <Text style={styles.contentText}>{content}</Text>
        </View>
        
        {/* Signature */}
        <View style={styles.signature}>
          <Text style={styles.signatureLine}>_______________________________</Text>
          <Text style={styles.signatureName}>{signatureName || "Nome do Responsável"}</Text>
          <Text style={styles.signatureTitle}>{signatureTitle || "Cargo do Responsável"}</Text>
        </View>
      </Page>
    </Document>
  );
};

// The Document Generator Component
function ReactPDF({ pdfPreviewMode = false }) {
  const [documentData, setDocumentData] = useState({
    title: 'Comunicado Oficial',
    content: 'Digite o conteúdo do documento aqui...',
    signatureName: 'Diretoria Jardim Amazônia',
    signatureTitle: 'Administração',
  });
  
  // Always initialize as hidden, regardless of pdfPreviewMode
  const [showPreview, setShowPreview] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setDocumentData(prev => ({
      ...prev,
      [name]: value
    }));
  };
  
  const generatePreview = () => {
    setShowPreview(true);
  };

  // When in PDF Preview mode, render the PDF document instead of the form
  if (pdfPreviewMode) {
    return (
      <PDFPreview 
        document={<OfficialDocument title={documentData.title} content={documentData.content} signatureName={documentData.signatureName} signatureTitle={documentData.signatureTitle} />}
        fileName={`documento-${documentData.title.toLowerCase().replace(/\s+/g, '-')}.pdf`}
        showPrintButton={true}
      />
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold text-primary mb-6">Criar Documento Oficial</h2>
      
      <div className="space-y-4 mb-6">
        {/* Title Field */}
        <div>
          <label className="block text-gray-700 mb-2">Título</label>
          <input
            type="text"
            name="title"
            value={documentData.title}
            onChange={handleChange}
            className="w-full p-2 border rounded"
          />
        </div>
        
        {/* Content Field */}
        <div>
          <label className="block text-gray-700 mb-2">Conteúdo</label>
          <textarea
            name="content"
            value={documentData.content}
            onChange={handleChange}
            className="w-full p-2 border rounded h-40"
          />
        </div>
        
        {/* Signature Fields */}
        <div className="grid grid-cols-2 gap-4">
          <div>
            <label className="block text-gray-700 mb-2">Assinatura</label>
            <input
              type="text"
              name="signatureName"
              value={documentData.signatureName}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
          
          <div>
            <label className="block text-gray-700 mb-2">Cargo</label>
            <input
              type="text"
              name="signatureTitle"
              value={documentData.signatureTitle}
              onChange={handleChange}
              className="w-full p-2 border rounded"
            />
          </div>
        </div>
      </div>
      
      {/* Preview Actions */}
      <div className="flex justify-center gap-4 mb-6">
        {!showPreview && (
          <button 
            onClick={generatePreview} 
            className="px-4 py-2 bg-primary text-white rounded-md hover:bg-primary-dark"
          >
            Mostrar Prévia
          </button>
        )}
        
        {showPreview && (
          <button 
            onClick={() => setShowPreview(false)} 
            className="px-4 py-2 bg-secondary text-white rounded-md hover:bg-secondary-dark"
          >
            Esconder Prévia
          </button>
        )}
      </div>
      
      {/* PDF Preview */}
      {showPreview && (
        <div className="mt-8">
          <h3 className="text-xl font-bold text-center text-primary mb-4">
            Prévia do Documento
          </h3>
          <PDFPreview 
            document={<OfficialDocument {...documentData} />}
            fileName={`${documentData.title.replace(/\s+/g, '-').toLowerCase()}.pdf`}
            height="500px"
          />
        </div>
      )}
    </div>
  );
}

export default ReactPDF;